import React, { FC } from "react";
import { useField } from "formik";

interface Props {
  name: string;
  type?: string;
  infoText?: string;
  className?: string;
  placeholder?: string;
}

const FormInput: FC<Props> = (props) => {
  const [field, { error, touched }] = useField({
    name: props.name,
    type: props.name,
  });

  let classes =
    props.className + " form-control";
  if (touched && error) {
    classes +=
      " is-invalid";
  }

  return (
    <div>
        <input className={classes} {...field} id={props.name} type={props.type} placeholder={props.placeholder} />
      
        {props.infoText && <div className="form-text text-muted font-size-sm">{props.infoText}</div>}

        {touched && error ? <div className="invalid-feedback">{error}</div> : null}    
    </div>
  );
};

FormInput.defaultProps = {
  type: "text"
};

export default FormInput;
