import React, { FC } from "react";
import { useField } from "formik";

interface Props {
  name: string;
  label: string;
}

const FormCheckbox: FC<Props> = (props) => {
  const [field, { error, touched }] = useField({
    name: props.name,
    type: props.name,
  });
  return (
    <div className="custom-control custom-checkbox">
        <input
          className="custom-control-input"
          id={props.name}
          type="checkbox"
          {...field}
        />
        <label className="custom-control-label" htmlFor={props.name} dangerouslySetInnerHTML={{ __html: props.label }} />
        
        {touched && error ? (
          <div className="invalid-feedback">{error}</div>
        ) : null}
    </div>
  );
};

export default FormCheckbox;
