import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getApiEndpoint } from "../client/Utils";

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "fi",
    supportedLngs: ["fi", "en", "sv"],
    backend: {

		/*	
			Must hardcode fi language for now because dynamic "lng" does not work as should.
			The main problem is that wrong langugage version of terms is used if other languages than fi is found.
		*/
    	//   loadPath: `${getApiEndpoint()}/api/translation?lang={{lng}}`,
		loadPath: `${getApiEndpoint()}/api/translation?lang=fi`,
    },
  });

export default i18n;
