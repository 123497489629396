import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./scss/style.scss";
import App from "./App";
import "./i18n/config";
import reportWebVitals from "./reportWebVitals";
import CookieNotification from "./components/Common/CookieNotification";

ReactDOM.render(
  
  <React.StrictMode>
    <Suspense 
      fallback={
        <div 
          className="min-vh-100 d-flex justify-content-center align-items-center"
          role="alert" 
          aria-busy="true"
        >
          <i className="fad fa-spinner-third fa-3x fa-spin text-secondary" title="Ladataan"></i>
        </div>
      }
    >
      <App />
      <CookieNotification />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
