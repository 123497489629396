import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormInput from "../Common/FormInput";
import FormCheckbox from "../Common/FormCheckbox";
import Alert from "../Common/Alert";
import Button, { ButtonStyle } from "../Common/Button";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ApiException, Client, SignUpDto } from "../../client/Client";
import HeaderLogo from "../../Images/template/medixine-online-logo@2x.png";
import { getApiEndpoint } from "../../client/Utils";

function SignUp() {
  
  useEffect(() => { document.title = t("ID_SIGNUP_PAGE_TITLE"); });

  const [errorMessage, setErrorMessage] = useState<string>();
  let history = useHistory();
  const { t } = useTranslation();

  const submitForm = (
    model: SignUpDto,
    helpers: FormikHelpers<SignUpDto>
  ) => {
    setErrorMessage(undefined);
    
    grecaptcha.ready(function () {
      grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITEKEY as string, {
          action: "submit",
        })
        .then(function (token) {
          const client = new Client(getApiEndpoint());
          model.reCaptchaToken = token;
          client
            .signup(new SignUpDto(model))
            .then(() => {
              history.replace("/signup/thanks");
            })
            .catch((e: ApiException) => {
              // Enable submit button
              helpers.setSubmitting(false);
              if (e.response) {
                // Show error from backend. Pick first from error list.
                const err = JSON.parse(e.response);

                if ("ModelState" in err) {
					const keys = Object.keys(err.ModelState);
					// setErrorMessage(err.ModelState[keys[0]][0]);

					const overrideUniqueErrors: Function = function(emsg: string) : string {

						const template: string = t("ID_PROPERTY_VALIDATION_ERROR_UNIQUE");

						if (emsg === template.replace("$1", t("ID_USER_NAME")) || emsg === template.replace("$1", t("ID_USERNAME")))
							return template.replace("$1", t("ID_SIGNUP_FIELD_EMAIL"));
						else if (emsg === template.replace("$1", t("ID_NAME")))
							return template.replace("$1", t("ID_SIGNUP_FIELD_ORGANIZATION"));
						else return emsg;
					};

					setErrorMessage(overrideUniqueErrors(err.ModelState[keys[0]][0]));

                } else if ("errors" in err) {
                  const keys = Object.keys(err.errors);
                  setErrorMessage(err.errors[keys[0]][0]);
                } else {
                  setErrorMessage(t("ID_API_ERROR"));
                }
              } else {
                // No specific error, show generic message.
                setErrorMessage(t("ID_API_ERROR"));
              }
            });
        });
    });
  };

  return (
    <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center sign-up">
      <div className="container-lg">

        <div className="d-flex justify-content-between align-items-center mx-3 mx-lg-0 my-5">
          <div>
              <a href="/">
                <img
                  className="header-logo"
                  src={HeaderLogo}
                  alt="medixine online logo"
                />
              </a>
          </div>
          <div>
            <a href={process.env.REACT_APP_LINK_LOGIN}>
              <i className="fas fa-lock-alt mr-2"></i>
              {t("ID_HEADER_LOGIN")}
            </a>
          </div>
        </div>
        <div className="sign-up-content row bg-white shadow-sm mb-lg-4">
          <aside className="sign-up-aside col-lg-4 bg-primary">
            <div className="px-3 px-xl-6 py-6">
              
              <div className="sign-up-aside-content">
                <div className="text-white mb-6">
                  <h1 className="mb-4 font-size-2xl">
                    {t("ID_SIGNUP_SIDE_TITLE")}
                  </h1>
                  <p className="font-size-lg line-height-lg">
                    {t("ID_SIGNUP_SIDE_BODY")}
                  </p>
                </div>

                <div className="sign-up-aside-offer text-light p-3 p-md-4">
                  <div className="mb-3">
                    <i className="fad fa-lightbulb-on fa-2x text-success"></i>
                  </div>
                  <h3 className="font-size-lg">
                    {t("ID_SIGNUP_SIDE_OFFER_TITLE")}
                  </h3>
                  <p>Kokeilujakson jälkeen palvelun hinta on 35€/kk/ammattilainen.</p>
                </div>
              </div>
            </div>
          </aside>

          <main className="sign-up-form-container col-lg-8">
            <div className="sign-up-form px-3 px-xl-6 py-6">

                <h2 className="font-size-2xl">
                  {t("ID_SIGNUP_MAIN_TITLE")}
                </h2>
                <p className="mb-6 font-size-lg">{t("ID_SIGNUP_MAIN_INFO")}</p>
                 
                <Formik<SignUpDto>
                  initialValues={
                    {
                      email: "",
                      firstName: "",
                      lastName: "",
                      organization: "",
                      password: "",
                      passwordRepeat: "",
                      phone: "",
                      termsAndPrivacy: false,
                      contract: false,
                    } as SignUpDto
                  }
                  onSubmit={submitForm}
                  validationSchema={Yup.object({
                    organization: Yup.string()
                      .required(t("ID_SIGNUP_VALIDATION_ORGANIZATION_REQUIRED"))
                      .matches(
                        new RegExp("^[\\w\\-\\_\\ åäöÅÄÖ]{4,64}$"),
                        t("ID_SIGNUP_VALIDATION_ORGANIZATION")
                      ),
                    firstName: Yup.string().required(
                      t("ID_SIGNUP_VALIDATION_FIRSTNAME")
                    ),
                    lastName: Yup.string().required(
                      t("ID_SIGNUP_VALIDATION_LASTNAME")
                    ),
                    email: Yup.string()
                      .required(t("ID_SIGNUP_VALIDATION_EMAIL_REQUIRED"))
                      .email(t("ID_SIGNUP_VALIDATION_EMAIL")),
                    phone: Yup.string()
                      .required(t("ID_SIGNUP_VALIDATION_PHONE_REQUIRED"))
                      .matches(
                        new RegExp("\\+?\\d{1,5}-?\\d{4,10}\\b"),
                        t("ID_SIGNUP_VALIDATION_PHONE")
                      ),
                    termsAndPrivacy: Yup.boolean()
                      .required()
                      .oneOf(
                        [true],
                        t("ID_SIGNUP_VALIDATION_TERMS_AND_PRIVACY")
                      ),
                    contract: Yup.boolean()
                      .required()
                      .oneOf([true], t("ID_SIGNUP_VALIDATION_CONTRACT")),
                    password: Yup.string()
                      .required(t("ID_SIGNUP_VALIDATION_PASSWORD"))
                      .matches(
                        new RegExp("(?=^.{8,60}$)(?=.*\\d)(?=.*\\D)\\S*"),
                        t("ID_SIGNUP_VALIDATION_PASSWORD")
                      ),
                    passwordRepeat: Yup.string().oneOf(
                      [Yup.ref("password"), null],
                      t("ID_SIGNUP_VALIDATION_PASSWORD_VERIFY")
                    ),
                  })}
                >
                  {({ isValid, isSubmitting }) => (
                    <Form>
                      <div className="form-group row">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="organization"
                        >
                          {t("ID_SIGNUP_FIELD_ORGANIZATION")}
                        </label>
                        <div className="col-sm-8">
                          <FormInput name="organization" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="firstName"
                        >
                          {t("ID_SIGNUP_FIELD_FIRSTNAME")}
                        </label>
                        <div className="col-sm-8">
                          <FormInput name="firstName" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="lastName"
                        >
                          {t("ID_SIGNUP_FIELD_LASTNAME")}
                        </label>
                        <div className="col-sm-8">
                          <FormInput name="lastName" />
                        </div>
                      </div>
                      <div className="form-group row mb-6">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="phone"
                        >
                          {t("ID_SIGNUP_FIELD_PHONE")}
                        </label>
                        <div className="col-sm-8">
                          <FormInput name="phone" type="tel" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="email"
                        >
                          {t("ID_SIGNUP_FIELD_EMAIL")}
                        </label>
                        <div className="col-sm-8">
                          <FormInput
                            name="email"
                            infoText={t("ID_SIGNUP_FIELD_EMAIL_INFO")}
                            type="email"
                            placeholder={t("ID_SIGNUP_FIELD_EMAIL_PLACEHOLDER")}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="password"
                        >
                          {t("ID_SIGNUP_FIELD_PASSWORD")}
                        </label>
                        <div className="col-sm-8">
                          <FormInput
                            name="password"
                            infoText={t("ID_SIGNUP_FIELD_PASSWORD_INFO")}
                            type="password"
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-5">
                        <label
                          className="col-sm-4 col-form-label"
                          htmlFor="passwordRepeat"
                        >
                          {t("ID_SIGNUP_FIELD_PASSWORD_VERIFY")}
                        </label>
                        <div className="col-sm-8">
                          <FormInput name="passwordRepeat" type="password" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-4 col-form-label"></div>
                        <div className="col-sm-8">
                          <FormCheckbox
                            label={t("ID_SIGNUP_FIELD_TERMS_AND_PRIVACY_INFO")
                              .replace("$1", process.env.REACT_APP_LINK_TERMS!)
                              .replace("$2", process.env.REACT_APP_LINK_PRIVACY!)}
                            name="termsAndPrivacy"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-4 col-form-label"></div>
                        <div className="col-sm-8">
                          <FormCheckbox
                            label={t("ID_SIGNUP_FIELD_CONTRACT_INFO").replace(
                              "$1",
                              process.env.REACT_APP_LINK_CONTRACT!
                            )}
                            name="contract"
                          />
                        </div>
                      </div>
                      <div className="form-group row mt-6">
                        <div className="col-sm-4 col-form-label"></div>
                        <div className="col-sm-8">
                          {errorMessage && (
                            <Alert className="mb-5">
                              <span>{errorMessage}</span>
                            </Alert>
                          )}
                          <Button
                            className="px-4 mr-3"
                            disabled={!isValid || isSubmitting}
                            busy={isSubmitting}
                            text={t("ID_SIGNUP_OK")}
                            style={ButtonStyle.Primary}
                            type="submit"
                          />
                          <Button
                            className="px-4"
                            disabled={isSubmitting}
                            text={t("ID_SIGNUP_CANCEL")}
                            style={ButtonStyle.Outline}
                            onClick={() => history.push("/")}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
          </main>

        </div>
      </div>
    </div>
  );
}

export default SignUp;
