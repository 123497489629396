import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button, { ButtonStyle } from "./Button";

const ctcc = { expiry: 30, method: "1", version: "1" };

function CookieNotification() {
  const [hasConsent, setHasConsent] = useState(false);
  const { t } = useTranslation();

  const consentAcceptCookies = () => {
    var today = new Date();
    var expire = new Date();
    expire.setTime(today.getTime() + 3600000 * 24 * ctcc.expiry);
    document.cookie = `MxOnlineCookieConsent=${escape(
      ctcc.version
    )};expires=${expire.toUTCString()};path=/`;

    setHasConsent(true);
  };

  useEffect(() => {
    const cookieName = "MxOnlineCookieConsent";
    var theCookie = " " + document.cookie;
    var ind = theCookie.indexOf(" " + cookieName + "=");
    if (ind === -1) ind = theCookie.indexOf(";" + cookieName + "=");
    if (ind === -1) return;
    var ind1 = theCookie.indexOf(";", ind + 1);
    if (ind1 === -1) ind1 = theCookie.length;

    setHasConsent(
      ctcc.version ===
        unescape(theCookie.substring(ind + cookieName.length + 2, ind1))
    );
  }, []);

  if (hasConsent) {
    return null;
  }

  return (
    <div className="fixed-bottom p-3 bg-white d-flex justify-content-center align-items-center shadow-lg">
      <div
        dangerouslySetInnerHTML={{
          __html: t("ID_COOKIE_CONTENT").replace(
            "$1",
            process.env.REACT_APP_LINK_PRIVACY!
          ),
        }}
      ></div>
      <div>
        <Button
          className="ml-4"
          style={ButtonStyle.Secondary}
          text={t("ID_COOKIE_ACCEPT")}
          onClick={consentAcceptCookies}
        />
      </div>
    </div>
  );
}

export default CookieNotification;
