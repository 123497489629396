import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Button, { ButtonStyle } from "../Common/Button";
import { useTranslation } from "react-i18next";
import HeroImage from "../../Images/project/online-laakari-asiakas-mobiili.png";
import VartiainenImage from "../../Images/project/matti-vartiainen-ainone.png";
import MessagesScreenImage from "../../Images/project/medixine-online-screen-sm@2x.png";
import DocComputerDrawing from "../../Images/project/medixine-doctor-computer.svg";

import YthsLogo from "../../Images/project/yths-logo@2x.png";
import AklinikkaLogo from "../../Images/project/aklinikka-logo@2x.png";
import NestleLogo from "../../Images/project/nestle-health-science-logo@2x.png";
import HowzLogo from "../../Images/project/howz-logo@2x.png";
import LindeLogo from "../../Images/project/linde-logo@2x.png";
import RegionSyddanmarkLogo from "../../Images/project/region-syddanmark-logo@2x.png";

import SoteArrow from "../../Images/project/sote-arrow.svg";


function Home() {
  const { t } = useTranslation();

  return (
    <div className="landing">
      <section className="landing-hero-section">
        <div className="container-xl landing-hero">
          <Header />

          <div className="d-md-flex justify-content-between">
            <div className="mb-3">
              <h1 className="font-size-3xl font-size-sm-4xl mt-5 mb-3">
                {t("ID_HOME_TITLE")}
              </h1>
              <h2 className="font-size-xl font-size-sm-2xl font-weight-normal mb-6">
                {t("ID_HOME_SUBTITLE")}
              </h2>

              <ul className="list-unstyled font-weight-medium mb-8">
                <li className="d-flex align-items-center mb-3">
                  <i className="fad fa-2x fa-check text-success"></i>
                  <span className="ml-4 font-size-xl">
                    {t("ID_HOME_BULLET1")}
                  </span>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <i className="fad fa-2x fa-check text-success"></i>
                  <span className="ml-4 font-size-xl">
                    {t("ID_HOME_BULLET2")}
                  </span>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fad fa-2x fa-check text-success"></i>
                  <span className="ml-4 font-size-xl">
                    {t("ID_HOME_BULLET3")}
                  </span>
                </li>
              </ul>
              <p className="lead pt-4">
                Medixine Online on turvallinen ratkaisu <strong>etävastaanottoon</strong> ja <strong>viestintään</strong>. Palvelun käyttöönotto on nopeaa eikä vaadi erillisiä asennettavia ohjelmia, sovelluksia tai lisäosia. Rekisteröidy käyttäjäksi nyt - <strong>tarjoamme ensimmäisen kuukauden ilmaiseksi!</strong>
              </p>
            </div>

            <div className="mt-3 ml-2 mb-5 text-center">
              <img
                src={HeroImage}
                className="hero-image"
                alt=""
              />
            </div>
          </div>
         
          <div className="d-flex flex-column flex-md-row pt-4 mb-6 align-items-center align-items-md-start">
            <h3 className="sote-title font-size-2xl">
              {t("ID_HOME_SOTE")}                  
            </h3>
            <div className="mt-3 mr-4">
              <img
                className="sote-arrow"
                src={SoteArrow}
                alt=""
              />
            </div>
            <div className="mt-4 mr-md-4">
              <a className="btn btn-secondary btn-lg" href="/signup">
                {t("ID_HOME_START_TRIAL")}
              </a>
            </div>
            <div className="mt-4">
              Kokeilun jälkeen palvelun hinta on 
              <div><strong>35 € / kk / ammattilaiskäyttäjä</strong>.</div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <div className="container-xl">
          <div className="row no-gutters justify-content-center">            

            <div className="shadow-sm bg-white rounded-lg">
                <section className="p-3 p-md-6 landing-providers-header">
                  <div className="row no-gutters mr-md-n6">
                    <div className="col-md">
                      <h3>Miksi tarjoaisin etävastaanottoa?</h3>
                      <p className="lead mb-6">Monet terveydenhuollon toimenpiteet voidaan hoitaa myös etänä videon, chatin, kuvien ja viestien avulla. Laadukkaiden etätyökalujemme ansiosta tehostat hoitoprosessia ja teet töitä joustavasti paikasta riippumatta.</p>
                      
                      <h3>Medixine Online etävastaanotto sopii:</h3>
                      <h4>Yksityisille lääkäriasemille</h4> 
                      <p>Perusterveydenhuoltoon sekä erikoisterveydenhuollon käyttöön. Tietoturvallisen ja laadukkaan videoyhteyden ja viestinnän kautta vältytään turhalta matkustamiselta, ja hoidat enemmän potilaita samassa ajassa.</p>

                      <h4>Kuntoutukseen ja fysioterapiaan </h4>
                      <p>Pidä yhteyttä asiakkaisiin turvallisella viestitoiminnolla ja seuraa hoidon edistymistä laadukkaan videoyhteyden avulla.</p>

                      <h4>Mielenterveyspalveluihin</h4> 
                      <p>Videoyhteyden avulla tapaamisissa säilyy ihmiskontaktin lämpö. Viestitoiminnolla asiakkaan kanssa on helppo pitää yhteyttä tapaamisten välillä.</p>
                    </div>
                    <div className="col-md my-6 overflow-hidden text-center">
                      <img
                        className="sm-screen ml-md-6"
                        src={MessagesScreenImage}
                        alt=""
                        width="750"
                        height="436"
                      />
                    </div>
                  </div>
                </section>

                <section className="landing-modules p-3 p-md-6">
                  <div className="p-3 p-md-6 bg-light rounded">
                    <div className="row justify-content-center">
                      <h3 className="col-lg-9 font-weight-normal text-center mb-6 line-height-lg">
                        Selaimessa toimiva Medixine Online etävastaanottopalvelu sisältää seuraavat toiminnot:	 
                      </h3>
                    </div>
                    <div className="row">
                      <div className="col-md mb-4">
                        <div className="mb-3 text-secondary fill-current font-size-3xl">
                          <svg xmlns="http://www.w3.org/2000/svg" className="align-middle" width="68.171" height="49.238" viewBox="0 0 68.171 49.238">
                            <g id="mx-video-appointment" transform="translate(-52.79 -108)">
                              <circle id="Ellipse_34" data-name="Ellipse 34" cx="8.322" cy="8.322" r="8.322" transform="translate(59.65 125.531)"/>
                              <path id="Path_73" data-name="Path 73" d="M83.151,328.035v-1.755a9.461,9.461,0,0,0-9.459-9.461H62.249a9.461,9.461,0,0,0-9.459,9.459v1.756a1.819,1.819,0,0,0,1.822,1.819h26.72A1.819,1.819,0,0,0,83.151,328.035Z" transform="translate(0 -172.617)"/>
                              <path id="Path_74" data-name="Path 74" d="M363.546,108A10.06,10.06,0,1,0,373.6,118.057,10.059,10.059,0,0,0,363.546,108Zm7.1,12.7a.674.674,0,0,1-.918.628l-2.892-.8v.992a.674.674,0,0,1-.674.674h-8.081a.674.674,0,0,1-.674-.674V114.6a.674.674,0,0,1,.674-.674h8.081a.674.674,0,0,1,.674.674v1.148l2.873-1.035a.674.674,0,0,1,.937.62Z" transform="translate(-252.638 0)"/>
                              <path id="Path_75" data-name="Path 75" 
                              d="M178.806,163.234c-2.95,0-6.626.95-6.625-2,0-.242-1.826-6.734-1.793-6.974H137.3a2.377,2.377,0,0,0-2.377,2.382v29.941a2.377,2.377,0,0,0,2.377,2.377h42.731a2.377,2.377,0,0,0,2.377-2.377v-23.35A17.817,17.817,0,0,1,178.806,163.234Zm-20.016-4.111a6.359,6.359,0,1,1-6.361,6.36,6.359,6.359,0,0,1,6.361-6.36Zm11.6,22.831A1.387,1.387,0,0,1,169,183.341H148.58a1.387,1.387,0,0,1-1.387-1.387v-1.34a7.23,7.23,0,0,1,7.23-7.23h8.736a7.23,7.23,0,0,1,7.23,7.23Z" transform="translate(-67.891 -38.24)" opacity="0.6"/>
                            </g>
                          </svg>
                        </div>
                        <h4>
                          {t("ID_HOME_FEATURES_VIDEO_TITLE")}
                        </h4>
                        <p>Toimintavarma ja laadukas videoyhteys tuo vastaanoton potilaan kotisohvalle. Videoyhteys toimii vaivattomasti suoraan selaimessa, kaikilla yleisimmillä kameralla varustetuilla laitteilla.</p>
                      </div>
                      <div className="col-md mb-4">
                        <div className="mb-3 text-secondary fill-current font-size-3xl">
                          <svg xmlns="http://www.w3.org/2000/svg" className="align-middle" width="58" height="39.951" viewBox="0 0 58 39.951">
                            <g id="mx-secure-messages" transform="translate(-76.07 -136.09)">
                              <path id="Path_66" data-name="Path 66" d="M110.02,157.238a.517.517,0,0,0,.7.048l23.325-20.963a3.027,3.027,0,0,0-1.17-.234H88.017a3.031,3.031,0,0,0-1.257.274Z" transform="translate(-8.903)" opacity="0.6"/>
                              <path id="Path_67" data-name="Path 67" d="M76.393,146.18a3.038,3.038,0,0,0-.323,1.359v28.7a3.009,3.009,0,0,0,.281,1.271l16.967-16.135Z" transform="translate(0 -8.403)" opacity="0.6"/>
                              <path id="Path_68" data-name="Path 68" d="M328.147,297c-.017.025-.032.05-.047.075A.528.528,0,0,1,328.147,297Z" transform="translate(-209.895 -134.009)" opacity="0.6"/>
                              <path id="Path_69" data-name="Path 69" d="M275.88,161.139l9.347,9.028a8.471,8.471,0,0,1,8.192-3.1V147.131a3.035,3.035,0,0,0-.361-1.441Z" transform="translate(-166.406 -7.995)" opacity="0.6"/>
                              <path id="Path_70" data-name="Path 70" d="M126.181,254.821l-9.775-9.441-4.81,4.308a2.576,2.576,0,0,1-1.672.615,2.518,2.518,0,0,1-1.752-.709l-4.9-4.385L86.21,261.442a3.041,3.041,0,0,0,1.349.318h38.554A8.508,8.508,0,0,1,126.181,254.821Z" transform="translate(-8.445 -90.877)" opacity="0.6"/>
                              <path id="Path_71" data-name="Path 71" d="M364.847,301.443a1.986,1.986,0,0,0-2.1,1.535,7.258,7.258,0,0,0-.1,1.32h4.086c0-.336,0-.667-.016-.993A2.027,2.027,0,0,0,364.847,301.443Z" transform="translate(-240.565 -140.013)"/>
                              <path id="Path_72" data-name="Path 72" d="M330.822,272.72a10.16,10.16,0,1,0,10.158,10.159,10.16,10.16,0,0,0-10.158-10.159Zm5.017,14.27a.6.6,0,0,1-.606.606h-8.375a.608.608,0,0,1-.608-.606v-5.1a.608.608,0,0,1,.608-.6h.431c.052-2.889,1.428-4.567,3.773-4.567s3.7,1.686,3.743,4.567h.431a.6.6,0,0,1,.606.6Z" transform="translate(-206.91 -116.999)"/>
                            </g>
                          </svg>
                        </div>
                        <h4>
                          {t("ID_HOME_FEATURES_MESSAGES_TITLE")}
                        </h4>
                        <p>Turvallisten viestien avulla on helppo jakaa hoitoon liittyviä viestejä ja dokumentteja. Työkalu korvaa perinteisen sähköpostin, joka ei sovellu luottamuksellisten viestien lähettämiseen.</p>
                      </div>
                      <div className="col-md mb-4">
                        <div className="mb-3 text-secondary fill-current font-size-3xl">
                          <svg xmlns="http://www.w3.org/2000/svg" className="align-middle" width="57.778" height="45.78" viewBox="0 0 57.778 45.78">
                            <g id="mx-chat" transform="translate(-66.49 -116.2)">
                              <path id="Path_64" data-name="Path 64" d="M224.009,176.2h-16.26l-1.263,15.786a2.131,2.131,0,0,1-2.21,2.21l-15.786.316v6.314a3.107,3.107,0,0,0,3,3.157h18.786l9,8.367a.966.966,0,0,0,.947.158.674.674,0,0,0,.474-.631v-7.893h3.315a3.107,3.107,0,0,0,3-3.157V179.2A2.97,2.97,0,0,0,224.009,176.2Z" transform="translate(-102.741 -50.589)" opacity="0.6"/>
                              <path id="Path_65" data-name="Path 65" d="M102.009,116.2H69.489a2.97,2.97,0,0,0-3,3v21.627a3.107,3.107,0,0,0,3,3.157H72.8v7.893a.892.892,0,0,0,.474.789.965.965,0,0,0,.947-.158l9-8.525h18.786a3.107,3.107,0,0,0,3-3.157V119.2A2.97,2.97,0,0,0,102.009,116.2ZM78.488,132.46a2.21,2.21,0,1,1,2.21-2.21A2.2,2.2,0,0,1,78.488,132.46Zm7.262,0a2.21,2.21,0,1,1,2.21-2.21A2.2,2.2,0,0,1,85.749,132.46Zm7.262,0a2.21,2.21,0,1,1,2.21-2.21A2.2,2.2,0,0,1,93.011,132.46Z"/>
                            </g>
                          </svg>
                        </div>
                        <h4>
                          {t("ID_HOME_FEATURES_CHAT_TITLE")}
                        </h4>
                        <p>Hoida rutiinitapaukset nopeasti ja helposti. Potilas kirjautuu palveluun pankkitunnuksilla, ja ammattilaisella on käytössään kaksivaiheinen tunnistus. Tämän ansiosta luottamuksellisten tietojen käsittely on mahdollista myös chatissa.</p>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="p-3 p-md-6 landing-medixine">
                  <div className="row mr-md-n6">
                    <div className="col-md font-size-lg">
                      <h3>Medixine – etähoidon asiantuntija yli 20 vuoden kokemuksella </h3>
                      <p>Medixine on kotimainen etähoitoratkaisujen edelläkävijä jo vuodesta 2000. Palveluidemme piirissä on 2,5 miljoonaa potilasloppukäyttäjää, ja asiakkaita 7 eri maassa.</p>

                      <p>Medixine Online hyödyntää samaa teknologiaa, jolle olemme rakentaneet kattavan ja täysin räätälöitävän etähoidon alustamme Medixine Suiten.</p>

                      <p>Medixine Online Saas-palvelu tuo tärkeimmät työkalut etävastaanottoon ja viestintään ulottuvillesi vain sisäänkirjautumisen vaivalla – ei pitkiä integraatioprojekteja eikä erillisten ohjelmien asentamista koneellesi.</p>

                      <p><a href="http://www.medixine.fi" target="_blank" rel="noopener noreferrer">Lue lisää meistä: www.medixine.fi</a></p>
                    </div>
                    <div className="col-md my-6 overflow-hidden text-center">
                      <img
                        className="sm-screen ml-md-6"
                        src={DocComputerDrawing}
                        alt=""
                        width="338"
                        height="400"
                      />
                    </div>
                  </div>
                </section>

                <section className="landing-refes p-3 p-md-6">
                  <h3 className="font-weight-normal text-center mb-6 line-height-lg">
                    Mekin luotamme Medixineen
                  </h3>
                  <div className="row logo-cloud">
                    <div className="col">
                      <img src={YthsLogo} alt="Yths logo" width="225" height="34"/>
                    </div>
                    <div className="col">
                      <img src={AklinikkaLogo} alt="A-klinikka logo" width="180" height="60"/>
                    </div>
                    <div className="col">
                      <img src={NestleLogo} alt="Nestle logo" width="185" height="54"/>
                    </div>
                  </div>
                  <div className="row logo-cloud mb-6">
                    <div className="col">
                      <img src={HowzLogo} alt="Howz logo" width="144" height="48"/>
                    </div>
                    <div className="col">
                      <img src={LindeLogo} alt="Linde logo" width="114" height="57"/>
                    </div>
                    <div className="col">
                      <img src={RegionSyddanmarkLogo} alt="Region Syddanmark logo" width="180" height="66"/>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                      <p className="col-lg-7 font-weight-normal text-center mb-6">
                        Medixinen teknologiaa käyttävät muun muassa <strong>YTHS</strong> ja <strong>A-klinikka</strong> Suomessa sekä kansainvälisesti <strong>Nestlé Health Science</strong>, Ison-Britannian julkinen terveydenhoitopalvelu <strong>NHS</strong>, teollisuuskaasu- ja teknologiayhtiö <strong>Linde</strong> sekä sekä <strong>Tanskan pohjoinen ja eteläinen sairaala-alue</strong>.
                      </p>
                    </div>
                </section>

                <section className="landing-testimonial p-3 p-md-6">
                  <div className="p-3 p-md-6 bg-light rounded">
                    <div className="row justify-content-between">
                      <div className="col-md-8 d-md-flex">
                        <div className="text-success fill-current mr-4 mb-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="38.375" height="35.125" viewBox="0 0 38.375 35.125"><defs></defs><path d="M56.75-90.125q0,2.438-3.469,10.469T45.688-60.5H41.875q.312-10.625.313-18a67.1,67.1,0,0,1,.5-10.125,8.654,8.654,0,0,1,2.75-4.906A7.857,7.857,0,0,1,51-95.625a5.5,5.5,0,0,1,4.344,1.594A5.694,5.694,0,0,1,56.75-90.125Zm-23.5,0q0,2.438-3.469,10.469T22.188-60.5H18.375q.312-10.625.313-18a67.1,67.1,0,0,1,.5-10.125,8.654,8.654,0,0,1,2.75-4.906A7.857,7.857,0,0,1,27.5-95.625a5.5,5.5,0,0,1,4.344,1.594A5.694,5.694,0,0,1,33.25-90.125Z" transform="translate(-18.375 95.625)"/></svg>
                        </div>
                        <div>
                          <blockquote>
                            <p className="font-weight-light font-size-xl font-italic line-height-xl mb-0">Medixine on auttanut meitä tarjoamaan kokonaisvaltaista etähoitoa perheille, joiden jäsenillä on dementiaa. Alusta on hyvin intuitiivinen ja helppokäyttöinen.</p>
                          </blockquote>
                        </div>
                      </div>
                      <div className="landing-testimonial-person col-md-3">
                        <div>
                          <img src={HowzLogo} alt="Howz logo" width="109" height="36"/>
                        </div>
                        <div className="mt-3">
                          <strong>Louise Rogerson</strong><br/>
                          CEO, Howz / Intelesant (NHS)<br/>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="text-center p-4 p-md-6">
                
                <div className="mx-auto flex flex-col space-y-8">
                  <h3 className="mb-6">{t("ID_HOME_SOTE")}</h3>

                  <div className="mb-4">
                    <a className="btn btn-secondary btn-lg" href="/signup">
                      {t("ID_HOME_START_TRIAL")}
                    </a>
                  </div>

                  <p className="font-size-lg mb-6">
                    {t("ID_HOME_START_CALL_US")}
                    <br/>
                    <a 
                      className="text-nowrap" 
                      href="tel:35894520020"
                    >
                      <strong>+358 9 4520 020</strong>
                    </a>
                  </p>

                </div>
              </section>
              
              </div>

          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
