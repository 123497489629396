import React from "react";
import { useTranslation } from "react-i18next";
import HeaderLogo from "../../Images/template/medixine-online-logo@2x.png";

function Header() {
  const { t } = useTranslation();
  return (
    <header className="d-flex justify-content-between align-items-center py-3 py-md-4">
      <div>
        <a href="/">
          <img className="header-logo mw-100" src={HeaderLogo} alt="medixine online logo" />
        </a>
      </div>
      <div className="ml-2 mr-0">
        <a
          href={process.env.REACT_APP_LINK_LOGIN}
          className="btn btn-primary d-flex align-items-center font-weight-medium px-3 px-md-4"
        >
          <i className="fas fa-lock-alt mr-2"></i>
          {t("ID_HEADER_LOGIN")}
        </a>
      </div>
    </header>
  );
}

export default Header;
