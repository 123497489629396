import React, { useEffect } from "react";
import Home from "./components/Pages/Home";
import SignUp from "./components/Pages/SignUp";
import SignUpThanks from "./components/Pages/SignUpThanks";
import { BrowserRouter as Switch, Route, useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Switch>
      <ScrollToTop />
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/signup/thanks">
        <SignUpThanks />
      </Route>
      <Route exact path="/signup">
        <SignUp />
      </Route>
      <Route path="*"></Route>
    </Switch>
  );
}

export default App;
