import React from "react";

interface AlertProps {
  children: JSX.Element;
  className?: string;
}

function Alert(props: AlertProps) {

  let classes = "alert alert-danger";
  
  if (props.className) {
    classes += ` ${props.className} `;
  }

  return (
    <div 
      className={classes}
      role="alert"
    >
      {props.children}
    </div>
  );
}

export default Alert;
