import React, { useEffect } from "react";
import Button, { ButtonStyle } from "../Common/Button";
import HeaderLogo from "../../Images/template/medixine-online-logo@2x.png";
import { useTranslation } from "react-i18next";

function SignUpThanks() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Kiitos rekisteröitymisestä! - Medixine Online";
  });

  return (
    <div className="min-vh-100 d-flex flex-column justify-content-start justify-content-lg-center align-items-center sign-up">
      <div className="container-lg">
        
        <div className="d-flex justify-content-between align-items-center mx-3 mx-lg-0 my-5">
          <div>
              <a href="/">
                <img
                  className="header-logo"
                  src={HeaderLogo}
                  alt="medixine online logo"
                />
              </a>
          </div>
          <div>
            <a href={process.env.REACT_APP_LINK_LOGIN}>
              <i className="fas fa-lock-alt mr-2"></i>
              {t("ID_HEADER_LOGIN")}
            </a>
          </div>
        </div>
        <div className="sign-up-content row justify-content-center bg-white shadow px-3 py-5 py-lg-8">
          
          <main className="sign-up-form-container col-lg-7">
            <div className="mb-3">
              <i className="fad fa-check fa-4x text-success"></i>
            </div>
            <h2 className="font-size-2xl mb-3">{t("ID_SIGNUP_THANKS_TITLE")}</h2>
            <p className="font-size-lg">{t("ID_SIGNUP_THANKS_BODY")}</p>
            <p className="font-italic mb-6">{t("ID_SIGNUP_THANKS_BODY2")}</p>
            
            <Button
              className="btn-lg"
              text={t("ID_SIGNUP_THANKS_CONTINUE")}
              style={ButtonStyle.Primary}
              onClick={() => {
                window.location.href = process.env.REACT_APP_LINK_LOGIN!;
              }}
            />
          </main>

        </div>
      </div>
    </div>
  );
}

export default SignUpThanks;
