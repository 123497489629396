import React from "react";
import { useTranslation } from "react-i18next";
import FooterLogo from "../../Images/template/medixine-suite-logo-bw.svg";

function Footer() {
	const { t } = useTranslation();
	return (
		<footer className="container-xl py-6">
			<div className="d-md-flex justify-content-between text-center text-md-left">
				<div className="order-2 order-md-1 mb-4 mb-md-0">
					<a 
						href="https://www.medixine.fi/" 
						target="_blank"
						className="text-dark powered-by"
						rel="noopener noreferrer"
					>
						<div className="font-size-2xs">Powered by</div>
						<img src={FooterLogo} width="104" height="14" alt="Medixine Suite" />
					</a>
				</div>
				<ul className="order-1 order-md-2 list-inline font-size-sm">
					<li className="list-inline-item">
						<a 
							href={process.env.REACT_APP_LINK_TERMS}
							target="_blank"
							type="checkbox" 
							rel="noopener noreferrer"
						>
							{t("ID_FOOTER_LINK_TERMS")}
						</a>
					</li>
					<li className="list-inline-item">
						<a 
							href={process.env.REACT_APP_LINK_PRIVACY} 
							target="_blank"
							rel="noopener noreferrer"
						>
							{t("ID_FOOTER_LINK_PRIVACY")}
						</a>
					</li>
					<li className="list-inline-item">
						<a 
							href={process.env.REACT_APP_LINK_FEEDBACK}
							target="_blank"
							rel="noopener noreferrer"
						>
							{t("ID_FOOTER_LINK_FEEDBACK")}
						</a>
					</li>
				</ul>
			</div>
		</footer>
	);
}

export default Footer;
