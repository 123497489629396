import React from "react";

export enum ButtonStyle {
  Primary,
  Secondary,
  Outline,
}

interface ButtonProps {
  text: string;
  style: ButtonStyle;
  disabled?: boolean;
  className?: string;
  icon?: string;
  busy?: boolean;
  type?: any;
  onClick?: () => void;
}

function Button(props: ButtonProps) {
  
  let classes = "btn";
  if (props.className) {
    classes += ` ${props.className} `;
  }

  switch (props.style) {
    case ButtonStyle.Primary:
      classes += "btn-primary";
      break;
    case ButtonStyle.Secondary:
      classes += "btn-secondary";
      break;
    case ButtonStyle.Outline:
      classes += "btn-outline-primary";
      break;
  }

  return (
    <button
      type={props.type}
      className={classes}
      onClick={props?.onClick}
      disabled={props.disabled}
    >
      <div className="d-flex align-items-center">
        {props.icon && <i className={`${props.icon} mr-3`}></i>}
        {props.busy && <i className="fas fa-circle-notch text-success fa-spin mr-2"></i>}
        <span>{props.text}</span>
      </div>
    </button>
  );
}

export default Button;
